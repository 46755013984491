//@ts-nocheck
import { motion, useMotionValue, useTransform } from 'framer-motion'
import { useTranslation } from 'next-i18next';
import { FADE_BOTTOM } from '../animations'
import { useEffect, useRef, useState } from 'react'
import { PARTNERS } from '../resources/constants'
import { Element } from 'react-scroll';
import Image from 'next/image'
import { PopupButton } from "react-calendly";

interface PartnersProps {
}

export function Partners({ }: PartnersProps) {
  const { t, i18n } = useTranslation();
  const [popupRoot, setPopupRoot] = useState<HTMLElement | null>(null);

  const itemWidth = 140;  // assume each item is 200px wide
  // const visibleItemsCount = Math.floor(window.innerWidth / itemWidth);
  const totalItemsCount = PARTNERS.length * 2;  // doubled for mirroring

  // Create a doubled list of items for mirroring effect
  const allItems = [...PARTNERS, ...PARTNERS];

  const speed = 1;  // speed of the carousel
  const carouselRef = useRef(null);
  const x = useMotionValue(0);

  // Create an effect to update the x position
  useEffect(() => {
    const intervalId = setInterval(() => {
      let newX = x.get() - speed;
      if (newX < -totalItemsCount * itemWidth / 2) {
        newX = 0;  // reset to the beginning when reach the end
      }
      x.set(newX);
    }, 20);  // update every 20ms for smoothness
    return () => clearInterval(intervalId);
  }, [x]);

  useEffect(() => {
    setPopupRoot(document.getElementById("__next") || document.body);
  }, []);

  return (
    <Element name="partners" >
      <section id="partners" className="mt-16 lg:mt-28 w-full py-20 dark:text-white text-neutral-600 " >
        <div className="w-full mx-auto max-w-7xl" >

          <motion.div
            className="w-full px-4 xl:px-0 flex relative items-center"
            transition={{
              duration: 0.3,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >

            <motion.h2
              initial={FADE_BOTTOM.initial}
              whileInView={FADE_BOTTOM.final}
              transition={{
                duration: 0.3,
                delay: 0.3
              }}
              viewport={{ once: true }}
              className="text-3xl font-semibold leading-tight sm:text-4xl lg:text-4xl tracking-tight w-full text-center" >{t("Partners & Customers")}
            </motion.h2>


          </motion.div>

          <motion.p
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.3,
              delay: 0.3
            }}
            viewport={{ once: true }}
            className="text-base lg:text-lg tracking-tight dark:text-gray-400 text-gray-600 mt-8 lg:mt-12 px-2" >
            {t("We take pride in being trusted by foremost tech entities for delivering top-tier computer vision and machine learning solutions. Our blend of innovative technology and rigorous operational procedures facilitates the creation of superior ML models — because superior data processing leads to unparalleled AI.")}
          </motion.p>

        </div>

        <div className="carousel-container flex overflow-hidden mt-4 lg:mt-16 mx-auto max-w-7xl py-8 lg:py-12">
          <motion.div className="items flex gap-4" ref={carouselRef} style={{ x }}>
            {allItems.map((item, index) => (
              <div key={index} className="item rounded-lg shadow bg-white dark:bg-transparent px-4 py-1 m-4 flex items-center justify-center md:min-w-[160px] min-w-[140px] ">
                <Image height={80} width={160} className="dark:invert grayscale md:h-16 lg:h-20 h-12 object-contain" src={item} alt="" />
              </div>
            ))}
          </motion.div>
        </div>
      </section>
    </Element>
  );
}
