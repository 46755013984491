import { useRouter } from 'next/router';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label?: string;
}

export function Button({ label, primary, size = 'small', ...props }: ButtonProps) {
  const mode = primary ? 'button_primary' : 'button_secondary';
  const sizeStyle = `button_${size}`
  // const history = useHistory()
  const router = useRouter()

  const click = () => {
    if(props.type === "submit" ){
      return
    }
    
    const currentLocale = router.locale;
    const playgroundUrl = `https://catalog.deepneuronic.com`;
      window.open(playgroundUrl);
    // router.push(`/contact`, undefined, { locale: currentLocale });
  }

  return (
    <>
      {/* <button
        onClick={click}
        {...props}
        className={[, `py-2 min-w-[100px] dark:bg-white px-6 lg:px-7 rounded-lg font-bold text-sm dark:text-black text-white bg-black ${props.className}`].join(' ')}

      >
        {label ?? props.children}
      </button> */}
      <button
        onClick={click}
        {...props}
        className={[
          "relative group",
          "py-1.5",
          "lg:py-2",
          "min-w-[100px]",
          "px-6",
          "lg:px-8",
          "rounded-lg",
          "font-medium",
          "text-sm",
          "bg-button-gradient",
          "from-foreground-primary/10 via-container-background  to-foreground-primary/20 duration-300",
          "text-foreground-primary border border-foreground-primary",
          "shadow-[4px_4px_10px_rgba(234,239,246,0.1)] hover:shadow-[4px_4px_10px_rgba(234,239,246,0.2)]", // Soft glow effect
          props.className
        ].join(" ")}
      >
            <span
          className="
          absolute inset-0 
          rounded-lg
          bg-gradient-to-b
          from-transparent
          via-foreground-primary/10
          to-foreground-primary/10
          transition-opacity duration-300
          opacity-0
          group-hover:opacity-100
          pointer-events-none
          
        "
        />
        <span className="relative z-10">{label ?? props.children}</span>
      </button>
    </>
  );
}
