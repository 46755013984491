// import { useHistory } from "react-router-dom";
import { COMPANY_MENU, ICONS } from "../../resources/constants";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { scroller } from 'react-scroll';

export const Company = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter()

  const handleNavigate = (link: string) => {

    if (link === "/#partners") {
      // window.location.href = "#partners";
      scroller.scrollTo('partners', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }

    const currentLocale = router.locale;
    router.push(`${link}`, undefined, { locale: currentLocale });
  }

  return (
    <div className="p-12 whitespace-nowrap font-bold bg-container-background text-foreground-primary" >
      <ul className="" >
        {COMPANY_MENU.items.map((item) => (
          <li key={item.name} onClick={() => handleNavigate(item.link)} className="text-base cursor-pointer flex items-center mt-4 first:mt-0  hover:text-primary-300 group" >
            <span className=" w-10 h-10 rounded-full bg-card-background  mr-2 p-1 flex items-center justify-center" >
              <Image width={24} height={24} className=" w-6 group-hover:scale-110 duration-200" src={ICONS[item.icon]} alt={item.name} />
            </span>
            {t(`${item.name}`)}
          </li>
        ))}
      </ul>
    </div>
  )
};