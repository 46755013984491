import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion'
import { USE_CASES, UseCaseType } from '../resources/constants';
import { Button } from './Button';
import { shuffleArray } from '../utils';
import { GridCard } from './GridCard';
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router';
import Image from 'next/image';
import { FADE_BOTTOM } from '@/animations';
import { Player } from '@lottiefiles/react-lottie-player';
import serverAnimation from "../animations/serverAnimation.json"
import camerasAnimation from "../animations/camerasAnimation.json"
import { ImageWrapper } from './ImageWrapper';
import { ImageCarousel } from './ImageCarousel';
import { Cta } from './Cta';

interface HomeIntegrationSectionProps {
}

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

export function HomeIntegrationSection({ }: HomeIntegrationSectionProps) {
  const [useCases, setUseCases] = useState<UseCaseType[]>([])
  const [animationStep, setAnimationStep] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const [playerSizeRatio, setPlayerSizeRatio] = useState(1);
  const { t, i18n } = useTranslation("common");
  const targetRef = useRef(null);
  const player1 = useRef<any>(null);
  const player2 = useRef<any>(null);

  useEffect(() => {
    const shuffledUseCases = shuffleArray(USE_CASES);
    setUseCases(shuffledUseCases.slice(0, 3))
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        console.log("checking...")

        // Only consider the first entry (you should only be observing one element)
        const [entry] = entries;
        // Check if the element is visible
        if (entry.isIntersecting) {
          observer.disconnect();  // Optional: Stop observing after it is visible once
          console.log("visible")
          player1?.current?.play();

        }
      },
      {
        root: null,  // Sets the viewport as the bounding box (viewport)
        rootMargin: '0px',
        threshold: 0.9  // Trigger when 10% of the element is visible
      }
    );

    // Start observing the target element
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Optional: Cleanup function to unobserve element if it gets unmounted
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setPlayerSizeRatio(0.5);

      } else if (window.innerWidth >= 480 && window.innerWidth < 1024) {
        setPlayerSizeRatio(0.7);
      } else {
        setPlayerSizeRatio(1);
      }

      if (window.innerWidth < 720) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    };

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className="py-10 sm:py-16 lg:py-24 relative text-foreground-primary bg-gradient-to-b from-container-background/20 to-container-background" >


      <motion.div
        className="w-full px-4 xl:px-0 relative items-center mx-auto sm:px-6 lg:px-8 max-w-7xl"
        transition={{
          duration: 0.3,
          delay: 0.3
        }}
        viewport={{ once: true }}
      >

        <motion.h2
          initial={FADE_BOTTOM.initial}
          whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.3,
            delay: 0.3
          }}
          viewport={{ once: true }}
          className="text-3xl font-semibold leading-tight sm:text-4xl lg:text-5xl tracking-tight w-full text-center" >{t("Seamless System Integration")}
        </motion.h2>

        <motion.p
          initial={FADE_BOTTOM.initial}
          whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.3,
            delay: 0.5
          }}
          viewport={{ once: true }}
          className="px-4 xl:px-0 font-bold tracking-tight mt-4 text-base leading-relaxed text-foreground-tertiary w-full text-center">
          {t("Our innovative solution seamlessly integrates with your existing IP cameras, augmented by our cutting-edge AI models. This powerful combination introduces sophisticated local video analytics to automate your surveillance, monitoring, and inspection efforts.")}
        </motion.p>
      </motion.div>



      {/* <div ref={targetRef} className="flex items-center w-full justify-center relative flex-col md:flex-row my-8">
        <div>
          <div
            className="flex justify-center items-center"
            style={{ height: `${300 * playerSizeRatio}px`, }}
          >
            <Player
              ref={player1}
              onEvent={(event) => event === "complete" && (player2?.current?.play(), setAnimationStep(1))}
              loop={false}
              controls={false}
              keepLastFrame
              speed={2}
              src={camerasAnimation}
              style={{ height: `${140 * playerSizeRatio}px`, width: `${140 * playerSizeRatio}px` }}
            ></Player>
          </div>

          <motion.div
            className={`p-2 border border-primary-500 rounded-md text-cyan-500 text-xs md:text-sm text-center font-semibold ${isMobile ? "hidden" : "block"}`}
            initial="hidden"
            animate={animationStep >= 1 ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 1, delay: 1 }}
          >
            Isolated Network
          </motion.div>
        </div>

        {isMobile ? (
          <motion.div
            className="-mb-8 -mt-6"
            initial="hidden"
            animate={animationStep >= 1 ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 1 }}  // Customize the duration and other transition properties if needed
          >
            <svg width="2" height="60" viewBox="0 0 2 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="1" x2="1" y2="60" stroke="url(#paint0_linear_924_8)" strokeWidth="2" strokeDasharray="2 2" />
              <defs>
                <linearGradient id="paint0_linear_924_8" x1="0" y1="0" x2="-0.0183679" y2="56.7161" gradientUnits="userSpaceOnUse">
                  <stop offset="0.252012" stopColor="#31CFF2" />
                  <stop offset="0.954164" stopColor="#D816D1" />
                </linearGradient>
              </defs>
            </svg>

          </motion.div>
        ) : (
          <motion.div
            className="ml-8"
            initial="hidden"
            animate={animationStep >= 1 ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 1 }}  // Customize the duration and other transition properties if needed
          >
            <svg width="138" height="2" viewBox="0 0 138 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="137.014" y1="1" x2="-0.000930786" y2="1" stroke="url(#paint0_linear_593_581)" strokeWidth="2" strokeDasharray="2 2" />
              <defs>
                <linearGradient id="paint0_linear_593_581" x1="137.014" y1="0" x2="7.49827" y2="-0.0957834" gradientUnits="userSpaceOnUse">
                  <stop offset="0.252012" stopColor="#31CFF2" />
                  <stop offset="0.954164" stopColor="#D816D1" />
                </linearGradient>
              </defs>
            </svg>
          </motion.div>
        )}


        <div>
          <Player
            ref={player2}
            onEvent={(event) => event === "complete" && (setAnimationStep(2))}
            loop={false}
            controls={false}
            keepLastFrame
            speed={2}
            src={serverAnimation}
            style={{ height: `${300 * playerSizeRatio}px`, width: `${300 * playerSizeRatio}px`, }}
          ></Player>
          <motion.div
            className={`p-2 border border-primary-500 rounded-md text-cyan-500 text-xs md:text-sm text-center font-semibold ${isMobile ? "hidden" : "block"}`}
            initial="hidden"
            animate={animationStep >= 1 ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 1, delay: 1 }}
          >
            Internal Server
          </motion.div>
        </div>


        {isMobile ? (
          <motion.div
            className="mb-4"
            initial="hidden"
            animate={animationStep >= 1 ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 1 }}  // Customize the duration and other transition properties if needed
          >
            <svg width="2" height="60" viewBox="0 0 2 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="1" x2="1" y2="60" stroke="url(#paint0_linear_924_8)" strokeWidth="2" strokeDasharray="2 2" />
              <defs>
                <linearGradient id="paint0_linear_924_8" x1="0" y1="0" x2="-0.0183679" y2="56.7161" gradientUnits="userSpaceOnUse">
                  <stop offset="0.252012" stopColor="#31CFF2" />
                  <stop offset="0.954164" stopColor="#D816D1" />
                </linearGradient>
              </defs>
            </svg>

          </motion.div>
        ) : (
          <motion.div
            className="mr-8"
            initial="hidden"
            animate={animationStep >= 1 ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 1, delay: 0.5 }}  // Customize the duration and other transition properties if needed
          >
            <svg width="138" height="2" viewBox="0 0 138 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="137.014" y1="1" x2="-0.000930786" y2="1" stroke="url(#paint0_linear_593_581)" strokeWidth="2" strokeDasharray="2 2" />
              <defs>
                <linearGradient id="paint0_linear_593_581" x1="137.014" y1="0" x2="7.49827" y2="-0.0957834" gradientUnits="userSpaceOnUse">
                  <stop offset="0.252012" stopColor="#31CFF2" />
                  <stop offset="0.954164" stopColor="#D816D1" />
                </linearGradient>
              </defs>
            </svg>
          </motion.div>
        )}


        {isMobile ? (
          <motion.div
            initial="hidden"
            animate={animationStep >= 1 ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 1, delay: 1 }}  // Customize the duration and other transition properties if needed
          >
            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M27.5 7.46582L41.2815 21.2472C43.1121 23.0806 43.9172 25.4837 43.8065 27.8872C43.6953 30.3005 42.6619 32.7153 40.8175 34.5597L27.5001 21.2424V7.46582H27.5Z" fill="url(#paint0_linear_925_9)" />
              <path fillRule="evenodd" clipRule="evenodd" d="M41.2767 35.0188L27.5 48.7954V62.5719L44.7207 45.3513C50.4272 39.6448 50.4272 30.393 44.7207 24.6864L43.6935 23.6592C44.2742 25.0049 44.5203 26.4601 44.4534 27.915C44.335 30.4837 43.2399 33.0508 41.2859 35.0095L41.2768 35.0189L41.2767 35.0188ZM41.2814 21.247L41.2767 21.2422C41.3277 21.2933 41.3779 21.3449 41.4274 21.3969C41.3795 21.3466 41.3309 21.2965 41.2814 21.247Z" fill="url(#paint1_linear_925_9)" />
              <path fillRule="evenodd" clipRule="evenodd" d="M27.4944 28.5245C27.8346 28.5245 28.1685 28.5507 28.4942 28.6011C27.9701 29.2597 27.6568 30.0933 27.6568 31.0005C27.6568 33.1302 29.3831 34.8565 31.5128 34.8565C32.4199 34.8565 33.2535 34.5432 33.9121 34.0189C33.9626 34.3448 33.9888 34.6788 33.9888 35.0187C33.9888 38.6055 31.0813 41.5131 27.4943 41.5131C23.9076 41.5131 21 38.6056 21 35.0187C21 31.432 23.9075 28.5244 27.4943 28.5244L27.4944 28.5245Z" fill="#3B82F6" />
              <rect x="0.5" y="0.5" width="69" height="69" rx="7.5" stroke="url(#paint2_linear_925_9)" />
              <defs>
                <linearGradient id="paint0_linear_925_9" x1="27.4897" y1="7.46582" x2="27.4897" y2="62.3007" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#06B6D4" />
                  <stop offset="0.482362" stopColor="#3B82F6" />
                </linearGradient>
                <linearGradient id="paint1_linear_925_9" x1="27.4899" y1="8.11469" x2="27.4899" y2="61.8371" gradientUnits="userSpaceOnUse">
                  <stop offset="0.262439" stopColor="#06B6D4" />
                  <stop offset="0.862658" stopColor="#3B82F6" />
                </linearGradient>
                <linearGradient id="paint2_linear_925_9" x1="35" y1="0" x2="35" y2="70" gradientUnits="userSpaceOnUse">
                  <stop offset="0.26" stopColor="#06B6D4" />
                  <stop offset="0.86" stopColor="#3B82F6" />
                </linearGradient>
              </defs>
            </svg>
          </motion.div>
        ) : (
          <div>
            <div
              className="flex justify-center items-center "
              style={{ height: `${300 * playerSizeRatio}px`, }}
            >
              <motion.div
                className="-mb-10"
                initial="hidden"
                animate={animationStep >= 1 ? "visible" : "hidden"}
                variants={variants}
                transition={{ duration: 1, delay: 1 }}  // Customize the duration and other transition properties if needed
              >
                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M39.2852 10.666L58.973 30.3537C61.5882 32.9729 62.7383 36.4059 62.5801 39.8394C62.4213 43.287 60.945 46.7368 58.3102 49.3716L39.2853 30.3468V10.666H39.2852Z" fill="url(#paint0_linear_850_13)" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M58.9661 50.0275L39.2852 69.7084V89.3891L63.8862 64.7882C72.0383 56.6361 72.0383 43.4192 63.8862 35.267L62.4187 33.7995C63.2483 35.722 63.5999 37.8008 63.5043 39.8792C63.3351 43.5489 61.7707 47.2162 58.9793 50.0143L58.9662 50.0276L58.9661 50.0275ZM58.9729 30.3536L58.9661 30.3467C59.0389 30.4197 59.1107 30.4934 59.1814 30.5677C59.113 30.4958 59.0436 30.4243 58.9729 30.3536Z" fill="url(#paint1_linear_850_13)" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M39.2778 40.7501C39.7637 40.7501 40.2407 40.7876 40.7061 40.8595C39.9573 41.8005 39.5097 42.9913 39.5097 44.2872C39.5097 47.3296 41.9759 49.7959 45.0183 49.7959C46.3142 49.7959 47.505 49.3482 48.4459 48.5993C48.518 49.0648 48.5554 49.542 48.5554 50.0276C48.5554 55.1515 44.4018 59.3052 39.2776 59.3052C34.1537 59.3052 30 55.1517 30 50.0276C30 44.9037 34.1536 40.75 39.2776 40.75L39.2778 40.7501Z" fill="#3B82F6" />
                  <rect x="0.5" y="0.5" width="99" height="99" rx="7.5" stroke="url(#paint2_linear_850_13)" />
                  <defs>
                    <motion.linearGradient
                      animate={{
                        x1: [80, 100],
                        x2: [0, 80],
                        y1: [0, 80],
                        y2: [80, 0]
                      }}
                      transition={{
                        duration: 10,
                        repeat: Infinity,
                        ease: 'linear'
                      }}
                      id="paint0_linear_850_13" x1="39.2704" y1="10.666" x2="39.2704" y2="89.0015" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#06B6D4" />
                      <stop offset="0.482362" stopColor="#3B82F6" />
                    </motion.linearGradient>

                    <motion.linearGradient
                      animate={{
                        x1: [80, 100],
                        x2: [0, 80],
                        y1: [0, 80],
                        y2: [80, 0]
                      }}
                      transition={{
                        duration: 10,
                        repeat: Infinity,
                        ease: 'linear'
                      }}
                      id="paint1_linear_850_13" x1="39.2707" y1="11.5931" x2="39.2707" y2="88.3394" gradientUnits="userSpaceOnUse">
                      <stop offset="0.262439" stopColor="#06B6D4" />
                      <stop offset="0.862658" stopColor="#3B82F6" />
                    </motion.linearGradient>

                    <motion.linearGradient
                      animate={{
                        x1: [50, 100],
                        x2: [0, 50],
                        y1: [0, 80],
                        y2: [100, 0]
                      }}
                      transition={{
                        duration: 1,
                        repeat: Infinity,
                        ease: 'linear'
                      }}
                      id="paint2_linear_850_13" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
                      <stop offset="0.26" stopColor="#06B6D4" />
                      <stop offset="0.86" stopColor="#3B82F6" />
                    </motion.linearGradient>
                  </defs>
                </svg>

              </motion.div>

            </div>
            <motion.div
              initial="hidden"
              animate={animationStep >= 1 ? "visible" : "hidden"}
              variants={variants}
              transition={{ duration: 1, delay: 1 }}
              className="p-2 px-4 border border-primary-500 rounded-md  text-cyan-500 text-xs md:text-sm text-center font-semibold" >
              DeepNeuronic
            </motion.div>
          </div>

        )}



      </div> */}

      <div className="relative mt-16 w-full mx-auto max-w-7xl">
        <div className="absolute transform-gpu z-0 top-0 -left-[0%] blur-3xl opacity-10" >
          <svg id="visual" viewBox="0 0 300 300" width="300" height="300"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(164.26754773775951 120.47676207858599)">
              <path d="M27.8 -58.9C35.9 -43.4 42.3 -35.8 61.7 -27.3C81.1 -18.8 113.6 -9.4 120.5 4C127.4 17.3 108.7 34.7 97.7 57.8C86.7 80.9 83.4 109.7 68.3 134.9C53.3 160 26.7 181.5 2.4 177.3C-21.8 173.1 -43.7 143.3 -76.1 128.2C-108.6 113.1 -151.7 112.8 -150.5 93.9C-149.2 75 -103.6 37.5 -88 9C-72.4 -19.5 -86.9 -39 -89 -59.4C-91 -79.7 -80.8 -100.9 -63.9 -111.3C-47 -121.7 -23.5 -121.4 -6.8 -109.5C9.8 -97.7 19.7 -74.4 27.8 -58.9"
                fill="#2084F9"></path>
            </g>
          </svg>
        </div>

        <div className="absolute transform-gpu z-0 top-72 left-[50%] blur-3xl opacity-10" >
          <svg id="visual2" viewBox="0 0 900 600" width="900" height="600"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(443.57423807724894 308.9541724911854)">
              <path d="M79.8 -153.2C109.2 -121.2 142.9 -111.6 177.4 -89.8C211.8 -68 246.9 -34 228.3 -10.7C209.7 12.5 137.3 25 115.3 68.3C93.3 111.6 121.7 185.7 110.8 210C100 234.2 50 208.6 5.3 199.5C-39.5 190.4 -79 197.8 -90 173.7C-100.9 149.5 -83.4 93.7 -96.5 59.5C-109.5 25.3 -153.3 12.7 -184 -17.7C-214.8 -48.2 -232.5 -96.3 -211.4 -116.5C-190.2 -136.7 -130.1 -129 -88.4 -153.9C-46.7 -178.8 -23.3 -236.4 0.9 -238C25.2 -239.6 50.3 -185.2 79.8 -153.2"
                fill="#2084F9"></path>
            </g>
          </svg>
        </div>

        <div className="absolute transform-gpu z-0 top-[860px] -left-[5%] blur-3xl opacity-10" >
          <svg id="visual3" viewBox="0 0 300 300" width="300" height="300"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
            <g transform="translate(164.26754773775951 120.47676207858599)">
              <path d="M27.8 -58.9C35.9 -43.4 42.3 -35.8 61.7 -27.3C81.1 -18.8 113.6 -9.4 120.5 4C127.4 17.3 108.7 34.7 97.7 57.8C86.7 80.9 83.4 109.7 68.3 134.9C53.3 160 26.7 181.5 2.4 177.3C-21.8 173.1 -43.7 143.3 -76.1 128.2C-108.6 113.1 -151.7 112.8 -150.5 93.9C-149.2 75 -103.6 37.5 -88 9C-72.4 -19.5 -86.9 -39 -89 -59.4C-91 -79.7 -80.8 -100.9 -63.9 -111.3C-47 -121.7 -23.5 -121.4 -6.8 -109.5C9.8 -97.7 19.7 -74.4 27.8 -58.9"
                fill="#2084F9"></path>
            </g>
          </svg>
        </div>

        <div className=" mx-auto  relative text-md  py-10 px-10 md:px-20 rounded-xl  ">

          <div className="w-full h-auto " >

            <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-12 xl:gap-x-20 ">
              <div>
                <h2 className="text-lg sm:text-xl  font-bold tracking-tight ">{t("Traffic analysis")}</h2>
                <p className=" tracking-tight mt-1 text-gray-600 dark:text-gray-400">
                  {t("Enhance road safety with real-time traffic monitoring to detect congestion and identify accidents.")}
                </p>
                {/* <p className=" tracking-tight ">{t("home_grid_item1.2")} </p> */}
                <p className=" tracking-tight mt-1 text-gray-600 dark:text-gray-400">{t("home_grid_item1")}</p>

                <ul className="font-semibold tracking-tight dark:text-gray-400 text-gray-600 list-disc mt-2 pl-10">
                  <li>{t("Detect congestion")}</li>
                  <li>{t("Identify accidents")}</li>
                </ul>

              </div>
              {/* <ImageWrapper>
                <video
                  src="/videos/industries/crash.mp4"
                  className="w-full rounded-lg object-contain max-w-[420px]"
                  autoPlay
                  muted
                  loop
                />
              </ImageWrapper> */}
              <div
                className="w-full rounded-lg object-contain max-w-[520px]"
              >
                <ImageCarousel
                  images={["/videos/industries/crash.mp4"]}
                  alt={t("video")}
                  isVideo={true}
                  interval={4000}
                />
              </div>


            </div>

            <div className="w-full h-[1px] bg-gradient-to-r from-transparent via-foreground-tertiary to-transparent my-8 sm:my-12" ></div>

            <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-12 xl:gap-x-20 mb-12">
              <div >
                <h2 className="text-lg sm:text-xl font-bold tracking-tight ">{t("Crowd detection")}</h2>
                <p className=" tracking-tight mt-1 dark:text-neutral-400 text-gray-600">
                  {t("Optimize crowd safety by monitoring indoor/outdoor gatherings, ensuring social distancing, and accurately counting and classifying individuals.")}
                </p>
                {/* <p className=" tracking-tight ">{t("home_grid_item1.2")} </p> */}
                <p className=" tracking-tight mt-1 dark:text-neutral-400 text-gray-600">{t("home_grid_item2.2")}</p>

                <ul className="font-semibold tracking-tight dark:text-neutral-400 text-gray-600 list-disc mt-2 pl-10">
                  <li>{t("Monitor indoor/outdoor crowds")}</li>
                  <li>{t("Ensure social distancing")}</li>
                  <li>{t("Count and classify people")}</li>
                </ul>

              </div>
              {/* <ImageCarousel>
                <Image
                  width={400}
                  height={220}
                  className="w-full max-w-[420px] rounded-lg object-contain"
                  src="/images/industries/crowd.gif"
                  alt="crowd"
                />
              </ImageCarousel> */}

              <ImageCarousel
                images={["/images/industries/crowd.gif"]}
                alt={t("crowd")}
                isVideo={false}
                interval={4000}
              />


            </div>
            <div className="w-full h-[1px] bg-gradient-to-r from-transparent via-foreground-tertiary to-transparent my-8 sm:my-12" ></div>

            <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-12 xl:gap-x-20">
              <div>
                <h2 className="text-lg sm:text-xl font-bold tracking-tight ">{t("Public safety")}</h2>
                <p className=" tracking-tight mt-1 dark:text-neutral-400 text-gray-600">
                  {t("Enhance public safety with instant risk detection to identify threats, abandoned items, and prevent violence.")}
                </p>
                {/* <p className=" tracking-tight ">{t("home_grid_item1.2")} </p> */}
                <p className=" tracking-tight mt-1 dark:text-neutral-400 text-gray-600">{t("Instant risk detection:")}</p>
                <ul className="font-semibold tracking-tight dark:text-neutral-400 text-gray-600 list-disc mt-2 pl-10">
                  <li>{t("Identify threats")}</li>
                  <li>{t("Abandoned items")}</li>
                  <li>{t("Violence")}</li>
                </ul>

              </div>
              {/* <ImageWrapper>
                <Image
                  width={400}
                  height={220}
                  className="w-full max-w-[420px] rounded-lg object-contain"
                  src="/images/industries/weapon1.gif"
                  alt="weapon detection"
                />
              </ImageWrapper> */}

              <ImageCarousel
                images={["/images/industries/weapon1.gif"]}
                alt={t("crowd")}
                isVideo={false}
                interval={4000}
              />
            </div>

          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-8 space-y-4 md:space-y-0 md:space-x-4 md:flex-row lg:mt-12">

          <Cta >Book a demo</Cta>
        </div>
      </div>

    </section>
  );
}


