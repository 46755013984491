import { ICONS, SOLUTIONS_MENU } from '../../resources/constants';
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useState } from 'react';
import { classNames } from '../../utils';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';

export function Solutions({ }) {
  const router = useRouter();
  const [highlightedIndustry, setHighlightedIndustry] = useState(SOLUTIONS_MENU.items.first_column[0])
  const { t } = useTranslation();

  const handleIndustrySelection = (link: string) => {
    router.push(`${link}`);
  }

  const handleSolutionSelection = (link: string) => {
    router.push(`${link}`);
  }


  const exploreAllSolutions = () => {

      window.gtag('event', 'click', {
        'event_category': 'cta',
        'event_label': 'Try now',
        'value': '1'
      });

      const catalogUrl = `https://catalog.deepneuronic.com`;
      window.open(catalogUrl, '_blank', 'noopener,noreferrer');

  }

  return (
    <div className=" flex bg-container-background text-foreground-primary" >
      <div className="pt-11 px-12 pb-20 mr-4 bg-card-background whitespace-nowrap border-r border-card-border" >
        <h2 className="mb-4" >{t("Industries")}</h2>
        <ul className="mt-8">
          {SOLUTIONS_MENU.items.first_column.map((item) => (
            <li
              key={item.id}
              onMouseEnter={() => setHighlightedIndustry(item)}
              // onMouseLeave={() => setHighlightedIndustry(null)}
              onClick={() => handleIndustrySelection(item.link)}
              className={classNames(
                "font-bold text-sm cursor-pointer flex items-center mt-4 first:mt-0 group hover:text-primary-400",
                highlightedIndustry.id === item.id ? "text-primary-400" : ""
              )}
            >
              <span className=" w-12 h-12 rounded-full bg-container-background mr-2 p-1 flex items-center justify-center" >
                <Image width={28} height={28} className=" w-7 group-hover:scale-110 duration-200 " src={item.icon} alt={item.name} />
              </span>
              {t(`${item.name}`)}
            </li>

          ))}
        </ul>
      </div>

      <div>
        <div className="flex items-center h-full">
          <div className="px-4 mr-4 w-[320px]" >
            <div className="" >
              <Image width={300} height={189} className="rounded-[10px]" src={highlightedIndustry.image} alt={highlightedIndustry.name} />
              <div
                onClick={() => handleIndustrySelection(highlightedIndustry.link)}
                className="cursor-pointer flex hover:text-primary-500 mt-4" >
                <p>
                  {t("Explore industry")}
                </p>
                <ChevronRightIcon className="w-4 ml-1" />
              </div>
            </div>
          </div>

          <div className="mr-8 w-[300px]" >
            <div className="" >
              <div className=" w-full overflow-hidden flex items-center gap-2" >
                <Image width={36} height={36} className=" duration-200" src={highlightedIndustry.icon} alt={highlightedIndustry.name} />
                <p className="text-lg">
                  {t(`${highlightedIndustry.name}`)}
                </p>
              </div>
              <div className="h-[1px] my-4 w-full bg-gradient-to-r from-transparent via-card-divider to-transparent opacity-50" />
              <div className="text-left box-border">
    
                <p className="mt-2 text-foreground-tertiary box-border w-full" >
                  {t(`${highlightedIndustry.description}`)}
                </p>
              </div>
            </div>

          </div>
        </div>

   
      </div>
    </div>
  );
}
