import { ReactNode } from 'react';
import { classNames } from '../utils';

interface HomeCardProps {
  children: ReactNode;
  selected?: number
}

export function HomeCard({ children, selected = 3 }: HomeCardProps) {
  
  return (
    <div className={
      classNames(
        "glowTopBorder image-wrapper  relative mx-auto max-w-6xl w-full overflow-hidden h-[420px] p-10 rounded-3xl  bg-container-background",
        selected === 1 && "shadow-lg shadow-primary-600/30",
        selected === 2 && "shadow-lg shadow-[#f142b6]/30",
        selected === 3 && "shadow-lg shadow-[#ffbc31]/30",
        selected === 4 && "shadow-lg shadow-[#06b6d4]/30",
      )} >
      <div className="absolute top-0 left-0 w-full h-[500px] flex z-0" >
        {/* <div className="w-[calc(50%-100px)] bg-primary-700 h-full " ></div>
        <div className="w-[calc(50%+100px)] bg-neutral-800 h-full border-l-[200px] border-t-[500px] border-l-primary-700 border-t-transparent " ></div> */}
      </div>
      {/* <div className="flex z-10 relative w-full h-full items-center justify-between " > */}
        {children}
      {/* </div> */}

    </div>
  );
}
