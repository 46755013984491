import { ReactNode, useEffect } from 'react';

interface GridCardProps {
  children: ReactNode;
  onSelect: () => any
  pattern?: boolean
  fixedWidth?: boolean
}

export function GridCard({ children, onSelect, pattern, fixedWidth=true }: GridCardProps) {

  const handleOnMouseMove = (e:any) => {
    const cards = document.getElementsByClassName("card");
    
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i] as HTMLElement;
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      card.style.setProperty("--mouse-x", `${x}px`);
      card.style.setProperty("--mouse-y", `${y}px`);
    }
  }

  useEffect(() => {
    const updateCursor = ({ x, y }:any) => {
      document.documentElement.style.setProperty("--x", x);
      document.documentElement.style.setProperty("--y", y);
    };

    document.body.addEventListener("pointermove", updateCursor);

    return () => {
      document.body.removeEventListener("pointermove", updateCursor);
    };
  }, []);

  return (
    <>
      <div onClick={onSelect} onMouseMove={handleOnMouseMove} className={`h-full bg-gradient-to-br from-container-border/70 to-container-border duration-200 rounded-[10px] ${fixedWidth && "max-w-[320px]"} `}>
        {/* <div className="card-border" ></div> */}
        <div className={`${pattern ? "pattern" : ""} h-[calc(100%-2px)]  w-[calc(100%-2px)] m-[1px] bg-container-background  flex group flex-col items-center p-5 lg:p-6 rounded-[10px] z-[1] relative light_effect_card`} >
        {children}
        </div>
      </div>
    </>
  );
}
