import { useState, useEffect } from 'react';
import { ImageWrapper } from './ImageWrapper';

interface ImageCarouselProps {
  images: string[];
  alt?: string;
  interval?: number; // Interval time in milliseconds (default: 3000ms)
  isVideo?: boolean
}

export function ImageCarousel({ images, alt = '', interval = 3000, isVideo = false }: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (images.length <= 1) return; 

    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images, interval]);

  return (
    <ImageWrapper>
      {isVideo ? (
        <video 
        className="object-cover w-full h-full rounded-[10px] transition-opacity duration-500"
        src={images[currentIndex]}
        muted
        loop
        autoPlay
        ></video>
      ) : (
        <img
          className="object-cover w-full h-full rounded-[10px] transition-opacity duration-500"
          src={images[currentIndex]}
          alt={alt}
        />
      )}

    </ImageWrapper>
  );
}
