import { classNames } from "@/utils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { PopupModal } from "react-calendly";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label?: string;
}
const URL = "https://calendly.com/jose-mendes-deepneuronic/30min"
export function Cta({ label, primary = true, size = 'medium', ...props }: ButtonProps) {
  const sizeStyle = size === "small" ? "py-1 lg:py-2" : "py-2 lg:py-3"
  const [modalOpen, setModalOpen] = useState(false)
  const [popupRoot, setPopupRoot] = useState<HTMLElement | null>(null);

  // const history = useHistory()
  const router = useRouter()

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Restore scroll
    }

    return () => {
      document.body.style.overflow = ""; // Ensure cleanup on unmount
    };
  }, [modalOpen]);


  const click = () => {
    const currentLocale = router.locale;

    if (primary) {
      window.gtag('event', 'click', {
        'event_category': 'cta',
        'event_label': 'Book demo',
        'value': '1'
      });
      setModalOpen(true);
      // const playgroundUrl = `https://playground.deepneuronic.com`;
      // window.open(playgroundUrl, '_blank', 'noopener,noreferrer');
    } else {
      window.gtag('event', 'click', {
        'event_category': 'cta',
        'event_label': 'Contact us',
        'value': '1'
      });
      router.push(`/contact`, undefined, { locale: currentLocale });
    }

  }

  useEffect(() => {
    setPopupRoot(document.getElementById("__next") || document.body);
  }, []);

  return (
    <>
      <button
        onClick={click}
        {...props}
        className={
          classNames(
            [`min-w-[140px] relative px-7 lg:px-8 rounded-lg font-bold ${props.className}  ${sizeStyle}`,].join(' '),
            primary ? "btn-grad" : "bg-transparent  text-cyan-400 btn-grad-border"
          )
        }
      >
        <span>
          {label ?? props.children}
        </span>
      </button>

      {popupRoot && (
        <PopupModal
          url={URL}
          onModalClose={() => setModalOpen(false)}
          open={modalOpen}
          rootElement={popupRoot}
        />
      )}


    </>
  );
}
