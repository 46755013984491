import { ReactNode } from 'react';

interface ImageWrapperProps {
  children: ReactNode;
}

export function ImageWrapper({ children }: ImageWrapperProps) {
  return (
    <div className="relative group image-wrapper p-4">
      {children}
    </div>
  );
}
