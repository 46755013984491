import styled from "styled-components";

export const DropdownStyles = styled.div`
  .dropdown-option {
    padding: 0 25px;
    outline: 0;
    font-size: 16px;
    transition: opacity 0.2s;
    &:hover,
    &:focus {
      opacity: 0.55;
    }
  }
  .dropdown-root {
    z-index: 10;
    position: absolute;
  }
  .dropdown-arrow {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: -6.5px;
      left: -8px;
      border-radius: 5px 0 0 0;
      transform: rotate(45deg);
      border-top: 3px solid #2084F9;
      border-left: 3px solid #2084F9;

    }
  }
  .dropdown-container {
    position: absolute;
    overflow: hidden;
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25);
    border-radius: 10px;
    border: 1px solid #2A3544;
    border-top: 3px solid #2084F9;
  }
  .dropdown-section {
    position: absolute;
  }
  .dropdown-background {
    position: absolute;
    bottom: 0;
    background: #f6f9fc;
    width: 100%;
    
  }
`;